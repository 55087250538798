/* Typography */
h1 {
  font-weight: normal;
  margin: 0;
  font-size: 24px;
}

h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.p-g {
  -ms-flex-wrap: wrap;
}
