.layout-footer {
  @include transition(margin-left $transitionDuration);
  background-color: $footerBgColor;
  // padding: 1em 2em;
  height: 1.5em;

  img {
    vertical-align: middle;
  }

  .footer-text {
    vertical-align: middle;
  }
}
